* {
  box-sizing: border-box;
  overscroll-behavior-x: none;
}
iframe {
  max-height: 100%;
}
body {
  margin: 0;
  font-family: "Lato";
  background-color: black;
}
body::-webkit-scrollbar { width: 0; }
@font-face {
  font-family: "Lato";
  src: url("../public/fonts/Lato/Lato-Regular.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "LatoBold";
  src: url("../public/fonts/Lato/Lato-Bold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "LatoThin";
  src: url("../public/fonts/Lato/Lato-Thin.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy/gilroy-bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "GilroyRegular";
  /*font-weight: 400;*/
  src: url("../public/fonts/Gilroy/Gilroy-Regular.otf");
}

@font-face {
  font-family: "GilroyMedium";
  /*font-weight: 500;*/
  src: url("../public/fonts/Gilroy/Gilroy-Medium.otf");
}

@font-face {
  font-family: "GilroySemibold";
  /*font-weight: 600;*/
  src: url("../public/fonts/Gilroy/Gilroy-Semibold.otf");
}

@font-face {
  font-family: "GilroyBold";
  /*font-weight: 700;*/
  src: url("../public/fonts/Gilroy/Gilroy-Bold.otf");
}

.modal_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_header {
  width: 100%;
  height: 44px;
  background-color: #1a1a1a;
  color: #999999;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.modal {
  box-sizing: border-box;
  top: 0;
  transform: none;
  z-index: 0;
  position: static;
  padding-bottom: 0;
  left: 50%;
  height: 800px;
  width: 1500px;
}

.modal iframe {
  height: calc(100% - 44px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  margin-top: 0;
  border: none;
}

#wrapper_heading {
  flex-grow: 1;
  min-width: 1px;
}

#popup-wrapper-close {
  background-color: #000;
  color: #fff;
  width: 18px;
  cursor: pointer;
  height: 18px;
  border-radius: 2px;
  line-height: 18px;
  font-family: "Lato";
  font-weight: 700;
  text-align: center;
  flex-shrink: 0;
}

.auth-form-window {
  width: 1920px;
  height: 1080px;
  background-color: #000;
  z-index: 9999;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
}

.auth-form-panel {
  width: 690px;
  border: 1px solid #808080;
  border-radius: 8px;
  color: #696969;
  font-size: 28pt;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 15px;
  background: #000;
}

.auth-form_close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: inline-block;
  color: #c0c0c0;
  font-size: 20pt;
  border: 3px solid #808080;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 25pt;
  margin-left: 7px;
}

.numbers-panel {
  width: 46%;
  display: inline-block;
  vertical-align: middle;
}

.numbers-line {
  margin-bottom: 20px;
}

.number {
  display: inline-block;
  color: #c0c0c0;
  font-size: 38pt;
  border: 3px solid #808080;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 58pt;
  margin-left: 7px;
}


.clear::before {
  content: "\D7";
  position: relative;
  transform: scale(2);
  display: block;
  top: -7px;
}

.back:before {
  content: "\2190";
  position: relative;
  top: -5px;
}

.input-data-wrapper {
  width: 52%;
  display: inline-block;
  vertical-align: middle;
}

.form-group-top {
  text-align: center;
  margin-bottom: 0px;
}

.form-group-bottom {
  text-align: center;
  margin-bottom: 0px;
  display: table;
}

.label-phone {
  font-size: 20pt;
}

.input-phone {
  width: 100%;
  border-radius: 30px;
  height: 50px;
  line-height: 38pt;
  border: none;
  color: #808080;
  outline: none;
  padding: 0 20px;
  text-align: center;
  font-size: 21pt;
}

.pin-wrap {
  display: table-cell;
  position: relative;
  vertical-align: bottom;
}

.pin-wrap:first-child {
  width: 55%;
}

.pin-wrap:last-child {
  width: 55%;
  padding-left: 5px;
}

.label-pin {
  font-size: 22pt;
  color: #696969;
}

.input-pin {
  width: 100%;
  border-radius: 30px;
  height: 50px;
  line-height: 38pt;
  border: none;
  color: #808080;
  outline: none;
  padding: 0 20px;
  text-align: center;
  font-size: 21pt;
}

.selected {
  border: 7px solid #ffcf01;
}

.input-btn {
  background: #ffcf01;
  min-width: 120px;
  border: none;
  border-radius: 30px;
  padding: 0 20px;
  font-size: 28pt;
  height: 50px;
  line-height: 35pt;
  outline: none;
  display: table-cell;
  position: relative;
  vertical-align: bottom;
  cursor: pointer;
  color: Black;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 28pt;
  margin: 0;
}

#sport_div_iframe {
  min-height: calc(100vh - 96px);
  background-color: black;
  margin-top: 96px;
}

#application-container {
  min-height: calc(100vh - 96px);
  background-color: #262626;
  margin-top: 96px;
}

.error-visible {
  display: block;
  width: 100vw;
  height: 86px;
  background-color: rgb(190, 6, 6);
  position: fixed;
  top: 0;
  z-index: 50;
  text-align: center;
  line-height: 3.3;
}

.error-hidden {
  display: block;
  width: 100vw;
  height: 86px;
  background-color: rgb(190, 6, 6);
  position: fixed;
  top: -96px;
  z-index: 50;
  text-align: center;
  line-height: 3.3;
}

.error {
  transition: 1s;
}

.error-text {
  font-family: "Lato";
  font-size: 24px;
  color: white;
  font-weight: 700;
}

/* колхоз колхозыч */
#popover > div:first-child {
  right: 30px;
}